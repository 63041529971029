<template>
  <base-form
    v-slot="slotProps"
    :model="settings"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    mode="edit"
  >
    <el-form-item label="Primärfarbe" prop="primary_color">
      <el-color-picker
        v-model="slotProps.model.primary_color"
      ></el-color-picker>
    </el-form-item>

    <el-divider></el-divider>

    <el-form-item label="Sekundärfarbe" prop="secondary_color">
      <el-color-picker
        v-model="slotProps.model.secondary_color"
      ></el-color-picker>
    </el-form-item>

    <el-divider></el-divider>

    <el-form-item label="Linkfarbe" prop="link_color">
      <theme-color-chooser
        v-model="slotProps.model.link_color"
        :primary-color="settings.primary_color"
        :secondary-color="settings.secondary_color"
      ></theme-color-chooser>
    </el-form-item>

    <el-divider></el-divider>

    <el-form-item
      label="Hauptnavigation Hintergrund"
      prop="nav_background_color"
    >
      <theme-color-chooser
        v-model="slotProps.model.nav_background_color"
        :primary-color="settings.primary_color"
        :secondary-color="settings.secondary_color"
      ></theme-color-chooser>
    </el-form-item>

    <el-divider></el-divider>

    <el-form-item label="Hauptnavigation Text" prop="nav_text_color">
      <theme-color-chooser
        v-model="slotProps.model.nav_text_color"
        :primary-color="settings.primary_color"
        :secondary-color="settings.secondary_color"
      ></theme-color-chooser>
    </el-form-item>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"
import ThemeColorChooser from "@/components/ThemeColorChooser"

export default {
  components: {
    BaseForm,
    ThemeColorChooser
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      rules: {
        organization_signup_snippet: [
          { required: true, message: "Bitte ausfüllen", trigger: "blur" }
        ],
        primary_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ],
        secondary_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ],
        body_bg_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ],
        link_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ],
        nav_background_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ]
      }
    }
  }
}
</script>
