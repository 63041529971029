import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/region_settings"

export default {
  async getSettings() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.region
  },
  async updateSettings(settings) {
    let response = await Repository.patch(`${resource}/update`, {
      region_id: store.state.auth.chosenRegion,
      region: settings
    })
    return response.data
  },
  async updateGeneralSettings(settings) {
    const formData = new FormData()
    const entries = Object.entries(settings)
    for (const [key, value] of entries) {
      formData.append(`region[${key}]`, value ? value : "")
    }
    formData.append(`region_id`, store.state.auth.chosenRegion)
    let response = await Repository.patch(`${resource}/update`, formData)
    return response.data
  }
}
