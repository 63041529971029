<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Farben">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadSettings"
        ></region-chooser>
      </template>
      <region-color-settings-form
        :loading="loading"
        :settings="settings"
        :form-submitted="saveSettings"
      ></region-color-settings-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import RegionRepository from "@/repositories/region_repository.js"
import RegionColorSettingsForm from "@/components/forms/RegionColorSettingsForm"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Farbeinstellungen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    RegionColorSettingsForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: false,
      settings: {}
    }
  },
  async created() {
    this.loadSettings()
  },
  methods: {
    async loadSettings() {
      this.loading = true

      try {
        let data = await RegionRepository.getSettings()
        this.settings = data.color_settings
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    async saveSettings(settings) {
      await RegionRepository.updateSettings(settings)
    }
  }
}
</script>
