<template>
  <div>
    <div>
      <el-radio v-model="linkColor" label="primary">Primärfarbe</el-radio>
      <el-radio v-model="linkColor" label="secondary">Sekundärfarbe</el-radio>
      <el-radio v-model="linkColor" label="custom">Eigene Farbe</el-radio>
    </div>
    <div v-if="linkColor === 'custom'" style="margin-top: 16px;">
      <el-color-picker v-model="color"></el-color-picker>
    </div>
    <div v-else class="previewWrapper">
      <div
        class="colorPreviewTile"
        :style="{ 'background-color': getThemeColor(value) }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: prop =>
        typeof prop === "string" || prop === undefined || prop === null,
      required: true
    },
    primaryColor: {
      validator: prop =>
        typeof prop === "string" || prop === undefined || prop === null,
      required: true
    },
    secondaryColor: {
      validator: prop =>
        typeof prop === "string" || prop === undefined || prop === null,
      required: true
    }
  },
  data() {
    return {
      color: null
    }
  },
  computed: {
    linkColor: {
      get() {
        if (this.value !== "primary" && this.value !== "secondary") {
          return "custom"
        } else {
          return this.value
        }
      },
      set(val) {
        if (val === "custom") {
          this.$emit("input", "#444444")
        } else {
          this.$emit("input", val)
        }
      }
    }
  },
  watch: {
    color(val) {
      this.$emit("input", val)
    },
    value(val) {
      this.color = val
    }
  },
  methods: {
    getThemeColor(name) {
      if (name === "primary") {
        return this.primaryColor
      } else if (name === "secondary") {
        return this.secondaryColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.previewWrapper {
  margin-top: 16px;
  width: 40px;
  height: 40px;
  padding: 4px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.colorPreviewTile {
  height: 100%;
  width: 100%;
}
</style>
